import { ListItemButton, ListItemText } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { NavLink, useNavigate } from "react-router-dom";

function UpdateP(props) {
  const navigate = useNavigate();

  function nav(params) {
    navigate("/");
  }
  function nav2(params) {
    navigate("/ombor");
  }
  const [urlofpage] = React.useState(window.location.pathname);
  const base_url = require("../API.json");

  const [products, setProducts] = useState([
    {
      id: 0,
      caterogy_id: 0,
      name: "",
      price: 5000,
      count: 5,
      type_id: 0,
      cyrrency: 0,
      is_active: 0,
      nbu_id: 0,
      type: 0,
      created_at: "",
      updated_at: "",
      caterogies: [
        {
          id: 0,
          name: "",
          type: 0,
          created_at: "",
          updated_at: "",
        },
      ],
    },
  ]);

  useEffect(() => {
    const url = new URL(base_url.url + "/api/products/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "POST",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setProducts(res.result));
  }, [base_url.url]);

  let i = 1;

  const [edit, setEdit] = useState({
    id: 0,
    caterogy_id: 0,
    name: "",
    price: 0,
    count: 0,
    type_id: 0,
    cyrrency: 0,
    is_active: 0,
    nbu_id: 0,
    type: 0,
    created_at: "",
    updated_at: "",
    caterogies: [],
  });
  const [modal, setModal] = useState(false);

  const onChangeHandler = (e) => {
    const newdata = { ...edit };
    newdata[e.target.name] = e.target.value;
    setEdit(newdata);
  };
  const checkbox = useRef();

  function EditModal(e) {
    setEdit(e);
    setModal(true);
  }
  function Close() {
    setModal(false);
    setEdit({
      id: 0,
      caterogy_id: 0,
      name: "",
      price: 0,
      count: 0,
      type_id: 0,
      cyrrency: 0,
      is_active: 0,
      nbu_id: 0,
      type: 0,
      created_at: "",
      updated_at: "",
      caterogies: [],
    });
  }
  // console.log(edit);

  const [type_id_, setType_id_] = useState([
    {
      id: 0,
      name: "",
      value: "",
      created_at: "",
      updated_at: "",
    },
  ]);

  useEffect(() => {
    const url = new URL(base_url.url + "/api/settings/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setType_id_(res.result));
  }, [base_url.url]);

  const [category, setCategory] = useState([
    {
      id: 0,
      name: "",
      type: 0,
      created_at: "",
      updated_at: "",
    },
  ]);

  useEffect(() => {
    const url = new URL(base_url.url + "/api/caterogies/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setCategory(res.result));
  }, [base_url.url]);
  const [value3, setValue3] = useState({
    count: 0,
  });
  function Send(e) {
    e.preventDefault();

    const url = new URL(base_url.url + "/api/products/edit");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    let body = {
      id: edit.id,
      caterogy_id: edit.caterogy_id,
      name: edit.name,
      price: edit.price,
      count: +value3.count,
      type_id: edit.type_id,
      is_active: checkbox.current.checked
        ? checkbox.current.checked
          ? 1
          : 0
        : "",
    };

    fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => Edited(res.result));
  }

  function Edited(params) {
    if (params === "Product updated successfully") {
      window.location.reload(false);
      setModal(false);
      setEdit({
        id: 0,
        caterogy_id: 0,
        name: "",
        price: 0,
        type_id: 0,
        cyrrency: 0,
        is_active: 0,
        nbu_id: 0,
        type: 0,
        created_at: "",
        updated_at: "",
        caterogies: [],
      });
    }
  }

  const onChangeHandler2 = (e) => {
    const newdata = { ...value3 };
    newdata[e.target.name] = e.target.value;
    setValue3(newdata);
  };

  const [categ, setCateg] = useState([
    {
      id: 0,
      name: "",
      type: 0,
      created_at: "",
      updated_at: "",
    },
  ]);

  const [cat, setCat] = React.useState(1);

  useEffect(() => {
    const url = new URL(base_url.url + "/api/caterogies/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setCateg(res.result));
  }, [base_url]);

  return (
    <div className="Updatee_P">
      <div className={modal ? "update_modal active" : "update_modal "}>
        <div className="name_back">
          <div>
            <p className="page_title">Maxsulot o'zgartirish ({edit.name})</p>
            <p>
              <span onClick={() => nav()}>Bosh sahifa </span>
              <span onClick={() => nav2()}>/ombor</span>{" "}
              <span className="cur_page">{urlofpage}</span>
            </p>
          </div>
          <button onClick={() => Close()}>Orqaga qaytish</button>
        </div>
        <form onSubmit={Send} className="Add_p" action="">
          <div>
            <select
              onChange={onChangeHandler}
              value={edit.caterogy_id}
              name="caterogy_id"
              id=""
            >
              <option value="0">Kategoriyani tanlang</option>
              {category.map((name) => (
                <>
                  <option value={name.id}>{name.name}</option>
                </>
              ))}
            </select>
          </div>
          <div>
            <input
              type="text"
              value={edit.name}
              name="name"
              onChange={onChangeHandler}
              placeholder="Nomi"
            />
          </div>
          <div>
            <input
              type="text"
              onChange={onChangeHandler}
              placeholder="Narxi"
              value={edit.price}
              name="price"
            />
          </div>
          <div>
            <input
              type="text"
              onChange={onChangeHandler2}
              placeholder="Miqdori"
              value={value3.count}
              name="count"
            />
          </div>
          <div>
            <select
              onChange={onChangeHandler}
              value={edit.type_id}
              name="type_id"
              id=""
            >
              <option value="0">Turini tanlang</option>
              {type_id_.map((name) => (
                <>
                  <option value={name.id}>{name.name}</option>
                </>
              ))}
            </select>
          </div>
          <div>
            <input
              type="checkbox"
              onChange={onChangeHandler}
              value={edit.is_active}
              name="js"
              ref={checkbox}
              id=""
            />
          </div>
          <div className="res_but">
            <button type="submit">Jo'natish</button>
          </div>
        </form>
      </div>
      <div className="name_back">
        <div>
          <p className="page_title">Maxsulot o'zgartirish</p>
          <p>
            <span onClick={() => nav()}>Bosh sahifa </span>
            <span onClick={() => nav2()}>/ombor</span>{" "}
            <span className="cur_page">{urlofpage}</span>
          </p>
        </div>
        <NavLink to="/ombor">
          <button>Orqaga qaytish</button>
        </NavLink>
      </div>
      <div className="catalogs">
        <div className="list">
          <h1
            style={{
              borderBottom: "0.05vw solid",
              paddingBottom: "2vh",
            }}
          >
            Kataloglar
          </h1>
          {categ.map((val) => (
            <ListItemButton key={val.id} onClick={() => setCat(val.id)}>
              <ListItemText primary={val.name} />
            </ListItemButton>
          ))}
        </div>
        <table border={1} style={{ height: "100%" }} className="ttable">
          <thead>
            <tr>
              <th>№</th>
              <th>Nomi</th>
              <th>Qiymati</th>
              <th>Narxi</th>
              <th>Valyuta</th>
              <th>Turi</th>
              <th>Holati</th>
              <th>O'zgartirish</th>
            </tr>
          </thead>
          <tbody>
            {/* && item.type === 0 */}
            {products
              .filter((item) => item.caterogy_id === cat)
              .map((val) => (
                <tr key={val.id}>
                  <td>{i++}</td>
                  <td>{val.name}</td>
                  <td>{val.count}</td>
                  <td>{val.price}</td>
                  <td>{val.cyrrency === 1 ? "USD" : "UZS"}</td>
                  <td>{val.type_id === 1 ? "Kg" : "Dona"}</td>
                  <td className={val.is_active === 1 ? "yoniq" : "ochiq"}></td>

                  {/* <td onClick={() => Clicked(val)}>{val.name}</td> */}
                  <td>
                    <>
                      <FiEdit onClick={() => EditModal(val)} />
                    </>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UpdateP;
