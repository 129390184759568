import { ListItemButton, ListItemText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

function MakeP(props) {
  const navigate = useNavigate();

  function nav(params) {
    navigate("/");
  }
  function nav2(params) {
    navigate("/ombor");
  }
  const [urlofpage] = React.useState(window.location.pathname);
  const base_url = require("../API.json");

  const [products, setProducts] = useState([
    {
      id: 0,
      caterogy_id: 0,
      name: "",
      price: 5000,
      count: 5,
      type_id: 0,
      cyrrency: 0,
      is_active: 0,
      nbu_id: 0,
      type: 0,
      created_at: "",
      updated_at: "",
      caterogies: [],
    },
  ]);

  useEffect(() => {
    const url = new URL(base_url.url + "/api/products/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "POST",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setProducts(res.result));
  }, [base_url.url]);

  let i = 1;

  const [edit, setEdit] = useState({
    id: 0,
    caterogy_id: 0,
    name: "",
    price: 0,
    count: 0,
    type_id: 0,
    cyrrency: 0,
    is_active: 0,
    nbu_id: 0,
    type: 0,
    created_at: "",
    updated_at: "",
    caterogies: [],
  });

  // console.log(edit2);
  const [modal, setModal] = useState(false);

  function EditModal(e) {
    setEdit(e);
    setModal(true);
  }
  function Close() {
    setModal(false);
  }
  // console.log(edit);

  // useEffect(() => {
  //   const url = new URL(base_url.url + "/api/settings/get");

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   };

  //   fetch(url, {
  //     method: "GET",
  //     headers,
  //   })
  //     .then((response) => response.json())
  //     .then((res) => setType_id_(res.result));
  // }, [base_url.url]);

  function getCountValue(object) {
    const count = Object.keys(object).filter((key) => key.includes("count"));

    return count.map((key) => object[key]);
  }

  // function getProductValue(object) {
  //   const product = Object.keys(object).filter((key) =>
  //     key.includes("product")
  //   );

  //   return product.map((key) => object[key]);
  // }
  const [max1, setMax1] = useState();
  const [max2, setMax2] = useState();
  const [max1V, setMax1V] = useState(0);
  const [max2V, setMax2V] = useState(0);
  const [maxV, setMaxV] = useState(0);
  let arr = [];
  let arr2 = [];

  if (max1 > 0) {
    arr = [edit.id, +max1];
    arr2 = [+maxV, +max1V];
    if (max2 > 0) {
      arr = [edit.id, +max1, +max2];
      arr2 = [+maxV, +max1V, +max2V];
    }
  } else if (max2 > 0) {
    arr = [edit.id, +max2];
    arr2 = [+maxV, +max2V];
  } else {
    arr = [edit.id];
    arr2 = [+maxV];
  }

  function Send(e) {
    e.preventDefault();

    const formData = new FormData(e.target);

    const value = Object.fromEntries(formData.entries());

    let materials = xomash
      .filter((item) => item.type === 1)
      .map((val) => val.id);

    const count = getCountValue(value);

    let result_materials = materials;
    let result_count = count;
    // let i = 0;

    // count.forEach((value, key) => {
    //   if (value !== "" && value !== "0") {
    //     if (i < count.length && key !== 0) {
    //       if (materials[key] < 14) {
    //         materials[key] = materials[key] - 1;
    //       }
    //       result_materials += materials[key] + ",";
    //       if (value !== undefined && value !== "") {
    //         result_count += value + ",";
    //       }
    //       i = i + 1;
    //     }
    //   }
    // });

    // console.log(materials);

    const url = new URL(base_url.url + "/api/products/make");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    let body = {
      product_id: arr,
      count: arr2,
      materials: result_materials,
      values: result_count,
    };

    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => Reload(res.result));
    // product_id: edit.id,
    // count: edit.count,
    // materials: xomash.filter((item) => item.type === 1).map((val) => val.id),
    // values:
    // xomash.filter((item) => item.type === 1).map((val) => val.count)
    // .then((res) => Edited(res.result));
  }

  function Reload(params) {
    alert(params);
    if (params === "Added count of product succesfully") {
      window.location.reload(false);
    }
  }

  // function Edited(params) {
  //   if (params === "Product updated successfully") {
  //     window.location.reload(false);
  //     setModal(false);
  //     setEdit({
  //       id: 0,
  //       caterogy_id: 0,
  //       name: "",
  //       price: 0,
  //       count: 0,
  //       type_id: 0,
  //       cyrrency: 0,
  //       is_active: 0,
  //       nbu_id: 0,
  //       type: 0,
  //       created_at: "",
  //       updated_at: "",
  //       caterogies: [],
  //     });
  //   }
  // }

  const [xomash, setXomash] = useState([
    {
      id: 0,
      caterogy_id: 0,
      name: "",
      price: 0,
      count: [],
      type_id: 0,
      cyrrency: 0,
      nbu_id: 0,
      type: 0,
      created_at: "",
      updated_at: "",
      caterogies: [],
    },
  ]);
  let inde = [];
  for (let index = 0; index < xomash.length; index++) {
    inde[index] = index;
  }
  const [xomash2, setXomash2] = useState([
    {
      count: 0,
    },
  ]);

  const onChangeHandler2 = (e) => {
    const newdata = { ...xomash2 };
    newdata[e.target.name] = e.target.value;
    setXomash2(newdata);
  };
  useEffect(() => {
    const url = new URL(base_url.url + "/api/products/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "POST",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setXomash(res.result));
  }, [base_url.url]);

  const [categ, setCateg] = useState([
    {
      id: 0,
      name: "",
      type: 0,
      created_at: "",
      updated_at: "",
    },
  ]);

  const [cat, setCat] = React.useState();

  useEffect(() => {
    const url = new URL(base_url.url + "/api/caterogies/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setCateg(res.result));
  }, [base_url]);

  return (
    <div>
      <div className={modal ? "update_modal active" : "update_modal "}>
        <div className="name_back">
          <div>
            <p className="page_title">Maxsulot o'zgartirish ({edit.name})</p>
            <p>
              <span onClick={() => nav()}>Bosh sahifa </span>
              <span onClick={() => nav2()}>/ombor</span>{" "}
              <span className="cur_page">{urlofpage}</span>
            </p>
          </div>
          <button onClick={() => Close()}>Orqaga qaytish</button>
        </div>
        <form onSubmit={Send} className="Add_p" action="">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div>
              <input
                type="text"
                value={edit.name}
                readOnly
                name="name"
                placeholder="Nomi"
              />
            </div>
            <div>
              <input
                type="text"
                onChange={(e) => setMaxV(e.target.value)}
                placeholder="Qiymati"
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div>
              <select name="" id="" onChange={(e) => setMax1(e.target.value)}>
                <option value="0">Maxsulot tanlang</option>
                {products.map((item) => (
                  <>
                    <option value={item.id}>{item.name}</option>
                  </>
                ))}
              </select>
            </div>
            <div>
              <input
                type="text"
                onChange={(e) => setMax1V(e.target.value)}
                placeholder="Qiymati"
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div>
              <select name="" id="" onChange={(e) => setMax2(e.target.value)}>
                <option value="0">Maxsulot tanlang</option>
                {products.map((item) => (
                  <>
                    <option value={item.id}>{item.name}</option>
                  </>
                ))}
              </select>
            </div>
            <div>
              <input
                type="text"
                onChange={(e) => setMax2V(e.target.value)}
                placeholder="Qiymati"
              />
            </div>
          </div>
          <div>
            <div className="xomashyolar">
              {xomash
                .filter((item) => item.type === 1 && xomash.length)
                .map((val, i) => (
                  <>
                    <input
                      type="text"
                      readOnly
                      value={val.name}
                      name={`product${i}`}
                    />
                    <input
                      type="text"
                      readOnly
                      value={val.type_id === 1 ? "Kg" : "Dona"}
                      name={`type_id${i}`}
                    />
                    <input
                      type="text"
                      name={`count${i}`}
                      onChange={onChangeHandler2}
                      value={val.count[val.id]}
                      placeholder="Miqdorni kiriting"
                    />
                  </>
                ))}
            </div>
          </div>
          <div className="res_but">
            <button type="submit">Jo'natish</button>
          </div>
        </form>
      </div>
      <div className="name_back">
        <div>
          <p className="page_title">Maxsulot qoshish</p>
          <p>
            <span onClick={() => nav()}>Bosh sahifa </span>
            <span onClick={() => nav2()}>/ombor</span>{" "}
            <span className="cur_page">{urlofpage}</span>
          </p>
        </div>
        <NavLink to="/ombor">
          <button>Orqaga qaytish</button>
        </NavLink>
      </div>
      <div className="catalogs">
        <div className="list">
          <h1
            style={{
              borderBottom: "0.05vw solid",
              paddingBottom: "2vh",
            }}
          >
            Kataloglar
          </h1>
          {categ.map((val) => (
            <ListItemButton key={val.id} onClick={() => setCat(val.id)}>
              <ListItemText primary={val.name} />
            </ListItemButton>
          ))}
        </div>
        <table border={1} style={{ height: "100%" }} className="ttable">
          <thead>
            <tr>
              <th>№</th>
              <th>Nomi</th>
              <th>Qiymati</th>
              <th>Narxi</th>
              <th>Valyuta</th>
              <th>Turi</th>
              <th>Holati</th>
              <th>Xomashyo qoshish</th>
            </tr>
          </thead>
          <tbody>
            {/* && item.type === 0 */}
            {products
              .filter((item) => item.caterogy_id === cat)
              .map((val) => (
                <tr key={val.id}>
                  <td>{i++}</td>
                  <td>{val.name}</td>
                  <td>{val.count}</td>
                  <td>{val.price}</td>
                  <td>{val.cyrrency === 1 ? "USD" : "UZS"}</td>
                  <td>{val.type_id === 1 ? "Kg" : "Dona"}</td>
                  <td className={val.is_active === 1 ? "yoniq" : "ochiq"}></td>
                  <td onClick={() => EditModal(val)}>+</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MakeP;
