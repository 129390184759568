import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

function WareHause(props) {
  const navigate = useNavigate();

  function nav(params) {
    navigate("/");
  }

  const [all_count, setAll_count] = useState([
    {
      count: 0,
      type_id: 0,
      cyrrency: 0,
      is_active: 0,
      nbu_id: 0,
      type: 0,
      created_at: "",
      updated_at: "",
      caterogies: [],
    },
  ]);

  let allCountMaxD = 0;
  let allCountMaxK = 0;
  let allCountXomashyoD = 0;
  let allCountXomashyoK = 0;

  all_count
    .filter((val) => val.type === 0 && val.type_id === 1)
    .forEach(function (obj) {
      allCountMaxD += obj.count;
    });
  all_count
    .filter((val) => val.type === 0 && val.type_id === 2)
    .forEach(function (obj) {
      allCountMaxK += obj.count;
    });
  all_count
    .filter((val) => val.type === 1 && val.type_id === 1)
    .forEach(function (obj) {
      allCountXomashyoD += obj.count;
    });
  all_count
    .filter((val) => val.type === 1 && val.type_id === 2)
    .forEach(function (obj) {
      allCountXomashyoK += obj.count;
    });

  useEffect(() => {
    const url = new URL("https://icecream1.dadabayev.uz/api/products/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "POST",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setAll_count(res.result));
  }, []);

  const [urlofpage] = React.useState(window.location.pathname);
  return (
    <div className="warehause">
      <div className="name_back">
        <div>
          <p className="page_title">ombor</p>
          <p>
            <span onClick={() => nav()}>Bosh sahifa</span>{" "}
            <span className="cur_page">{urlofpage}</span>
          </p>
          <h1 className="jami">
            Jami Maxsulotlar Miqdori: <br />{" "}
            <span>
              {allCountMaxD} Kg {allCountMaxK} Dona
            </span>
          </h1>
          <h1 className="jami">
            Jami Xomashyolar Miqdori: <br />{" "}
            <span>
              {allCountXomashyoD} Kg {allCountXomashyoK} Dona
            </span>
          </h1>
        </div>
        <NavLink to="/">
          <button>Orqaga qaytish</button>
        </NavLink>
      </div>
      <div className="buttons">
        <NavLink to="/kategoriyalar">
          <button>Kategoriyalar</button>
        </NavLink>
        <NavLink to="/maxsulot-qoshish">
          <button>Maxsulot qo'shish</button>
        </NavLink>
        <NavLink to="/maxsulot-yaratish">
          <button>Maxsulot Yaratish</button>
        </NavLink>
        <NavLink to="/maxsulotni-yangilash">
          <button>Maxsulot yangilash</button>
        </NavLink>
        <NavLink to="/narxlarning-ozgarishi">
          <button>Narxlarning o'zgarishi</button>
        </NavLink>
        <NavLink to="/tayyorlangan-maxsulotlar-haqida">
          <button>Tayyorlangan maxsulotlar haqida</button>
        </NavLink>
      </div>
    </div>
  );
}

export default WareHause;
